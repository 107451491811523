<template>
    <v-container fluid class="pa-0">
        <v-row v-if="$store.state.currentUser">

            <!--<v-card id="lateral">-->

            <v-form>
                <v-container fluid class="px-0 pb-0">
                    <v-row dense>
                        <v-col cols="auto">
                            <v-checkbox v-model="modulRetax" class="mt-0 pt-0" hide-details />
                        </v-col>
                        <v-col cols="auto">
                            <p class="text-justify text-hyphenate mr-10">
                                Beanstandungsverfahren 
                            </p>
                        </v-col>
                        <v-col><router-link to="/termsbeanstandungen" target="_blank" class="ml-8 mr-1 pt-0 nowrap-text">Nutzungsbedingungen Beanstandungsverfahren</router-link></v-col>
                        <v-col v-for="(n, i) in this.$store.state.currentUser.mandanten"
                               :key="n.bezeichnung"
                                class="shrink mt-n2">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-img v-if="n.mandant == 1" src="../assets/Logo_BARMER_1000x700.png" class="ml-16"
                                                max-height="40px" min-width="30px" max-width="6vw" contain
                                                v-on="on" />
                                    </template>
                                    <span>{{ n.bezeichnung }}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-img v-if="n.mandant == 66" src="../assets/Logo_MKK_1000x700.png" class="mx-auto"
                                                max-height="40px" min-width="30px" max-width="6vw" contain
                                                v-on="on" />
                                    </template>
                                    <span>{{ n.bezeichnung }}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-img v-if="n.mandant == 4" src="../assets/Logo_DAK_1000x700.png" class="mx-auto"
                                                max-height="40px" min-width="30px" max-width="6vw" contain
                                                v-on="on" />
                                    </template>
                                    <span>{{ n.bezeichnung }}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-img v-if="n.mandant == 3" src="../assets/Logo_HEK_1000x700.png" class="mx-auto"
                                                max-height="40px" min-width="30px" max-width="6vw" contain
                                                v-on="on" />
                                    </template>
                                    <span>{{ n.bezeichnung }}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-img v-if="n.mandant == 74" src="../assets/Logo_KKH_1000x700.png" class="mx-auto"
                                                max-height="40px" min-width="30px" max-width="6vw" contain
                                                v-on="on" />
                                    </template>
                                    <span>{{ n.bezeichnung }}</span>
                                </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="auto">
                            <v-checkbox v-model="modulZuzahlung" class="mt-0 pt-0" hide-details />
                        </v-col>
                        <v-col cols="auto">
                            <p class="text-justify text-hyphenate">
                                Zuzahlungsprüfung /-rückforderung
                            </p>
                        </v-col>
                        <v-col cols="auto"><router-link to="/termszuzahlung" target="_blank">Nutzungsbedingungen Zuzahlungsprüfung /-rückforderung</router-link></v-col>
                        <v-col cols="auto"
                               v-for="(n, i) in this.$store.state.currentUser.mandanten"
                               :key="n.bezeichnung"
                               class="shrink mt-n2">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-img v-if="n.mandant == 1" src="../assets/Logo_BARMER_1000x700.png"
                                           max-height="40px" min-width="30px" max-width="6vw" contain
                                           v-on="on" />
                                </template>
                                <span>{{ n.bezeichnung }}</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-img v-if="n.mandant == 66" src="../assets/Logo_MKK_1000x700.png" class="mx-auto"
                                           max-height="40px" min-width="30px" max-width="6vw" contain
                                           v-on="on" />
                                </template>
                                <span>{{ n.bezeichnung }}</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-img v-if="n.mandant == 4" src="../assets/Logo_DAK_1000x700.png" class="mx-auto"
                                           max-height="40px" min-width="30px" max-width="6vw" contain
                                           v-on="on" />
                                </template>
                                <span>{{ n.bezeichnung }}</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-img v-if="n.mandant == 3" src="../assets/Logo_HEK_1000x700.png" class="mx-auto"
                                           max-height="40px" min-width="30px" max-width="6vw" contain
                                           v-on="on" />
                                </template>
                                <span>{{ n.bezeichnung }}</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-img v-if="n.mandant == 74" src="../assets/Logo_KKH_1000x700.png" class="mx-auto"
                                           max-height="40px" min-width="30px" max-width="6vw" contain
                                           v-on="on" />
                                </template>
                                <span>{{ n.bezeichnung }}</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-btn color="primary" type="submit" class="mt-8 mr-8 pt-0"
                               left
                               :disabled="!isValid"
                               @click.stop.prevent="checkData">
                            Speichern
                        </v-btn>

                        <!-- User message -->
                        <v-alert border="left" :type="userMessageType" v-if="userMessage" class="mt-4">
                            {{ userMessage }}
                        </v-alert>
                    </v-row>

                </v-container>
            </v-form>
        </v-row>

        <v-dialog v-model="showDialog" persistent>
            <v-card>
                <v-card-title class="text-h6 text-md-h5 flex-nowrap align-start">
                     <v-icon color="primary" large left>mdi-order-bool-ascending-variant</v-icon>
                    <!--Modul Zuzahlungsprüfung abwählen-->
                    {{dialog.ueberschrift}}
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">
                    <p class="text-justify text-hyphenate mb-0" v-html="dialog.inhalt">
                        <!--{{dialog.inhalt}}-->
                    </p>
                    <v-row dense class="mt-2" v-if="addModulZZ || addModulRetax">
                        <v-col cols="auto">
                            <v-checkbox v-model="licenseAgreement" class="mt-0 pt-0" hide-details />
                        </v-col>
                        <v-col>
                            <p class="text-justify text-hyphenate">
                                Ich bestätige, die
                                <router-link v-if="addModulRetax" to="/termsbeanstandungen" target="_blank">
                                    <span> Nutzungsbedingungen</span>
                                </router-link>
                                <router-link v-else-if="addModulZZ" to="/termszuzahlung" target="_blank">
                                    <span> Nutzungsbedingungen</span>
                                </router-link>
                                des GFS-Service-Portals gelesen zu haben
                                und bin mit deren Geltung einverstanden.
                            </p>
                        </v-col>
                    </v-row>
                    <v-row dense class="mt-2" v-if="addModulRetax">
                        <v-col cols="auto">
                            <v-checkbox v-model="licenseAgreement3" class="mt-0 pt-0" hide-details />
                        </v-col>
                        <v-col>
                            <p class="text-justify text-hyphenate">
                                Ich <strong>verzichte</strong> hiermit gegenüber den jeweils am GFS-Service-Portal teilnehmenden
                                Krankenversicherungsträgern ("Teilnehmenden KVTs") – diese vertreten durch GFS –, soweit die
                                Retax-Regelungen des für das Verhältnis zwischen uns und dem jeweiligen Teilnehmenden KVT maßgeblichen
                                Rahmen- und Versorgungsvertrages die Schriftlichkeit der Retax-Ankündigungen / Beanstandungen und/oder
                                des Prüfungsergebnisses vorsehen, <strong>auf dieses Schriftformerfordernis</strong> und werde dessen
                                Nichteinhaltung nicht gegenüber GFS oder den Teilnehmenden KVTs rügen. Dieser Verzicht ist unwiderruflich
                                für die Zeit der Teilnahme am GFS-Service-Portal und ich bestätige ausdrücklich, dass während dieser Zeit
                                der Informationsaustausch im Rahmen des Beanstandungsverfahrens über das GFS-Service-Portal die
                                Schriftform ersetzt (auch wenn eine schriftliche Übermittlung von Retax-Ankündigungen / Beanstandungen
                                und/oder von Prüfungsergebnissen durch GFS bzw. den Teilnehmenden KVT in Ausnahmefällen zulässig bleibt).
                                Bei Retaxen, Einsprüchen und Entscheidungen erfolgt zusätzlich eine <strong>Benachrichtigung per E-Mail</strong>.
                            </p>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" @click="doCancel">
                        Abbrechen
                    </v-btn>
                    <v-btn color="primary" @click="doSave" :disabled="disabled" v-if="!this.dialog.kontoLoschen">
                        Bestätigen
                    </v-btn>
                    <v-btn color="primary" type="button" to="/kontoloeschen" v-else>
                        Konto löschen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
    import eventBus from '@/main'

    export default {
        name: 'Modulauswahl',

        data() {
            return {
                showDialog: false,
                vPassword: '',
                emailRules: [
                    v => !!v || 'Email-Adresse ist erforderlich',
                    v => /^.+@.+\..+$/.test(v) || 'Geben Sie eine gültige E-Mail-Adresse ein'
                ],
                errorMessage: '',
                errorCount: 0,
                showPasswort: false,
                modulZuzahlung: this.$store.state.module.zzRueckforderungWrite,
                modulRetax: this.$store.state.module.retaxWrite,
                dialog: {
                    ueberschrift: '',
                    inhalt: '',
                    kontoLoschen: false
                },
                licenseAgreement: false,
                licenseAgreement3: false,
                addModulZZ: false,
                addModulRetax: false,
                userMessage: '',
                userMessageType: '',
            }
        },

        watch: {
            vPassword() {
                if (this.showDialog && this.errorMessage !== "" && this.vPassword !== "") {
                    this.errorMessage = "";
                }
            }
        },

        created() {
            this.emailOld = this.emailNew = this.$store.state.currentUser.email;
        },

        computed: {
            isValid() {
                return this.modulZuzahlung !== this.$store.state.module.zzRueckforderungWrite || this.modulRetax !== this.$store.state.module.retaxWrite;
            },

            disabled: function () {
                return (!this.licenseAgreement && this.addModulZZ) || ((!this.licenseAgreement || !this.licenseAgreement3) && this.addModulRetax) 
            },
 
        },

        methods: {

            checkData() {
                console.log('this.modulZuzahlung: ', this.modulZuzahlung, 'this.modulRetax: ', this.modulRetax)
                if (!this.modulZuzahlung && !this.modulRetax) {
                    this.dialog.ueberschrift = "Wichtiger Hinweis";
                    this.dialog.inhalt = "Sie haben kein Modul ausgewählt. Es muss mindestens ein Modul gewählt werden. "
                    this.dialog.inhalt += "<br />Wenn Sie keine Funktion des Service-Portals nutzen möchten, so bitte wir Sie ihr Konto zu löschen.";
                    this.dialog.kontoLoschen = true;
                    this.showDialog = true;
                }
                else if (!this.modulZuzahlung && this.modulZuzahlung !== this.$store.state.module.zzRueckforderungWrite) {
                    this.dialog.ueberschrift = "Modul Zuzahlungsprüfung/-rückforderung abwählen";
                    this.dialog.inhalt = "Wir möchten Sie darauf hinweisen, dass wenn Sie das Modul Zuzahlungsprüfung/-rückforderung "
                    this.dialog.inhalt += "abwählen Sie mit sofortiger Wirkung die Zuzahlungsprüfung nicht mehr nutzen können. Die Zuzahlungsrückforderungen ";
                    this.dialog.inhalt += "können Sie noch weitere 2 Monate einsehen, aber keine Rückforderungen anlegen. ";
                    this.dialog.inhalt += "<br />Bitte beachten Sie, dass eine Änderung der gewählten Module ebenso Auswirkungen auf die vergebenen Rechte der ";
                    this.dialog.inhalt += "zusätzlich angelegten Benutzer (siehe Benutzerverwaltung) haben kann.";
                    this.showDialog = true;
                }
                else if (!this.modulRetax && this.modulRetax !== this.$store.state.module.retaxWrite) {
                    this.dialog.ueberschrift = "Modul Beanstandungsverfahren abwählen";
                    this.dialog.inhalt = "Wir möchten Sie darauf hinweisen, dass wenn Sie das Modul Beanstandungsverfahren abwählen Sie die Banstandungen und Einsprüche "
                    this.dialog.inhalt += "für 2 Monate noch einsehen können, aber keine Einsprüche anlegen.";
                    this.dialog.inhalt += "<br />Bitte beachten Sie, dass eine Änderung der gewählten Module ebenso Auswirkungen auf die vergebenen Rechte der ";
                    this.dialog.inhalt += "zusätzlich angelegten Benutzer (siehe Benutzerverwaltung) haben kann.";
                    this.showDialog = true;
                }
                else if (this.modulZuzahlung && this.modulZuzahlung !== this.$store.state.module.zzRueckforderungWrite) {
                    this.dialog.ueberschrift = "Modul Zuzahlungsprüfung/-rückforderung ergänzen";
                    this.dialog.inhalt = "Wir bitten Sie folgende Nutzungsbedingungen sorgfältig durchzulesen und diese zu bestätigen. "
                    this.dialog.inhalt += "Anschließend schalten wir Ihnen die Funktion frei.";
                    this.addModulZZ = true;
                    this.showDialog = true;
                }
                else if (this.modulRetax && this.modulRetax !== this.$store.state.module.retaxWrite) {
                    this.dialog.ueberschrift = "Modul Beanstandungsverfahren ergänzen";
                    this.dialog.inhalt = "Wir bitten Sie folgende Nutzungsbedingungen sorgfältig durchzulesen und diese zu bestätigen. "
                    this.dialog.inhalt += "Anschließend schalten wir Ihnen die Funktion frei.";
                    this.addModulRetax = true;
                    this.showDialog = true;
                }

            },

            initData() {
                this.showDialog = false;
                this.dialog.ueberschrift = "";
                this.dialog.inhalt = "";
                this.dialog.kontoLoschen = false;
                this.modulZuzahlung = this.$store.state.module.zzRueckforderungWrite;
                this.modulRetax = this.$store.state.module.retaxWrite;
                this.licenseAgreement = false;
                this.licenseAgreement3 = false;
                this.addModulZZ = false;
                this.addModulRetax = false;
                this.userMessage = '';
            },

            doCancel() {
                this.initData();
            },

            async doSave() {
                eventBus.$emit('resetLogoutTimer', {});
                this.showDialog = false;

                //wenn beide Module abgewählt sind, dann die Speicherung abbrechen
                if (!this.modulZuzahlung && !this.modulRetax)
                    return;

                let vModule = "";
                if (this.modulZuzahlung)
                    vModule += "1";
                if (this.modulRetax)
                    vModule += vModule !== "" ? "|2" : "2";
                console.log("vModule: ", vModule);

                try {
                    //Modulauswahl speichern
                    let response = await this.$http.post('module', {
                        Benutzer: this.$store.state.currentUser.benutzer,
                        Module: vModule
                    });

                    if (response.status === 200) {
                        console.log("update erfolgreich!")
                        //Module aufrufen
                        await this.getModule();
                        this.initData();
                    }
                }
                catch (error) {
                    if (error.response) {
                        this.userMessage = error.response.data.message;
                        this.userMessageType = 'error';
                        this.initData();
                    }
                }
            },

            convertDatum(datum) {
                //Zeit aus Datum entfernen
                let dt = datum;
                if (datum) {
                    dt = new Date(datum);
                }
                else {
                    dt = new Date();
                }
                dt.setHours(0, 0, 0, 0);
                return Date.parse(dt);
            },

            findUserRight(i) {
                //Benutzerrecht finden
                let ix = this.$store.state.currentUser.rechte.findIndex(r => r.recht === i);
                return ix !== -1
            },

            async getModule() {
                try {

                    let response = await this.$http.get('module');
                    if (response.status === 200) {
                        var result = response.data;
                        let module = {
                            retaxRead: false,
                            retaxWrite: false,
                            zuzahlungRead: false,
                            zzRueckforderungRead: false,
                            zzRueckforderungWrite: false
                        };

 
                        let dateNow = this.convertDatum("");
                        let dateOpen = Date.parse("12/31/9999");

                        result.forEach(r => {
                            let gbDate = this.convertDatum(r.gbDatum);

                            switch (r.modul) {
                                case 1:
                                    if (gbDate === dateOpen) {
                                        module.zuzahlungRead = true && this.findUserRight(3);
                                        module.zzRueckforderungRead = true && this.findUserRight(7);
                                        module.zzRueckforderungWrite = true && this.findUserRight(8);
                                    }
                                    else if (gbDate >= dateNow && gbDate < dateOpen)
                                        module.zzRueckforderungRead = true && this.findUserRight(7);
                                    break;
                                case 2:
                                    if (gbDate === dateOpen) {
                                        module.retaxRead = true && this.findUserRight(1);
                                        module.retaxWrite = true && this.findUserRight(2);
                                    }
                                    else if (gbDate >= dateNow && gbDate < dateOpen)
                                        module.retaxRead = true && this.findUserRight(1);
                                    break;
                            }
                        });

                        this.$store.commit('updateModule', {
                            retaxRead: module.retaxRead,
                            retaxWrite: module.retaxWrite,
                            zuzahlungRead: module.zuzahlungRead,
                            zzRueckforderungRead: module.zzRueckforderungRead,
                            zzRueckforderungWrite: module.zzRueckforderungWrite
                        });

                    }
                }
                catch (error) {
                    if (error.response) {
                        this.$store.commit('updateCurrentError', error.response.data);
                    }
                }
            },

        }
    }
</script>
<style scoped>
    .nowrap-text {
        white-space: nowrap;
    }
</style>